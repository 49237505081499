import { useEffect, useState } from 'react';
import _ from 'lodash';
import { ITEM_CHILDREN_TYPES, ITEM_DATA_STATUS, ITEM_TYPES, PREVIEW_ID } from '@/trendData/trendData.constants';
import {
  sqTrendCapsuleSetStore,
  sqTrendScalarStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
} from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';

interface AllTrendStoreItemsParams {
  includeSignalPreview?: boolean;
  excludeEditingCondition?: boolean;
  workingSelection?: boolean;
  excludeDataStatus?: ITEM_DATA_STATUS[];
  itemTypes?: ITEM_TYPES[];
  itemChildrenTypes?: ITEM_CHILDREN_TYPES[];
  transformer?: (items: any[]) => any;
  memoComparison?: (previous: unknown, next: unknown) => boolean;
  items?: any[];
}

/**
 * Query the TREND_STORES for a list of items. By default this will return only the items in the details pane.
 *
 * @example:
 * const items = useAllTrendStoreItems({ transformer: items => sortAndDecorateItems({ sort, items, ...rest }) });
 *
 * @param includeSignalPreview - include the preview signal (if available).
 * @param excludeEditingCondition - include the condition being edited (if there is one).
 * @param workingSelection - if true only selected items (or all if none are selected) will be returned
 * @param excludeDataStatus - items without these dataStatus will be returned (list of ITEM_DATA_STATUS)
 * @param itemTypes - only items with these types will be returned (list of ITEM_TYPES)
 * @param itemChildrenTypes - child types to include (list of ITEM_CHILDREN_TYPES)
 * @param transformer - function to call on _.thru for the items, this can be used to decorate or modify the items
 * so that expensive operations will only be performed when the items change. This function needs to return the
 * modified items.
 * @param memoComparison - comparison function to determine if two lists of items are equivalent for the purposes of
 * re-rendering
 * @param dependencies - array of dependencies to add to the useEffect that determine when the items need to be updated
 * @return Object[] list of items
 */
export function useAllTrendStoreItems(
  {
    includeSignalPreview = false,
    excludeEditingCondition = false,
    workingSelection = false,
    excludeDataStatus = [],
    itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
    itemChildrenTypes = [],
    transformer = _.identity,
    memoComparison = _.isEqual,
  }: AllTrendStoreItemsParams = {},
  dependencies = [],
) {
  const series = useFluxPath(sqTrendSeriesStore, (store) => store.items, memoComparison);
  const seriesAndPreview = useFluxPath(sqTrendSeriesStore, (store) => store.itemsAndPreview, memoComparison);
  const scalars = useFluxPath(sqTrendScalarStore, (store) => store.items, memoComparison);
  const capsules = useFluxPath(sqTrendCapsuleStore, (store) => store.items, memoComparison);
  const capsuleSets = useFluxPath(sqTrendCapsuleSetStore, (store) => store.items, memoComparison);
  const tables = useFluxPath(sqTrendTableStore, (store) => store.items, memoComparison);
  const metrics = useFluxPath(sqTrendMetricStore, (store) => store.items, memoComparison);

  const getItems = () => {
    const allItems = _.concat(
      includeSignalPreview ? seriesAndPreview : series,
      scalars,
      capsules,
      capsuleSets,
      tables,
      metrics,
    );

    return getAllTrendStoreItems({
      includeSignalPreview,
      excludeEditingCondition,
      workingSelection,
      excludeDataStatus,
      itemTypes,
      itemChildrenTypes,
      transformer,
      items: allItems,
    });
  };

  const [items, setItems] = useState(transformer === _.identity ? getItems() : []);

  useEffect(() => {
    setItems(getItems());
  }, [series, seriesAndPreview, scalars, capsules, capsuleSets, tables, metrics, ...dependencies]);

  return items;
}

export const getAllTrendStoreItems = ({
  includeSignalPreview = false,
  excludeEditingCondition = false,
  workingSelection = false,
  excludeDataStatus = [],
  itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
  itemChildrenTypes = [],
  transformer = _.noop,
  items,
}: AllTrendStoreItemsParams): any[] => {
  let trendItems = items;
  if (_.isEmpty(trendItems)) {
    const { items: series, itemsAndPreview: seriesAndPreview } = sqTrendSeriesStore;
    const { items: scalars } = sqTrendScalarStore;
    const { items: capsules } = sqTrendCapsuleStore;
    const { items: capsuleSets } = sqTrendCapsuleSetStore;
    const { items: tables } = sqTrendTableStore;
    const { items: metrics } = sqTrendMetricStore;

    trendItems = (includeSignalPreview ? seriesAndPreview : series).concat(
      scalars,
      capsules,
      capsuleSets,
      tables,
      metrics,
    );
  }

  return _.chain(trendItems)
    .filter((item) => _.includes(itemTypes, item.itemType))
    .reject((item) => _.includes(excludeDataStatus, item.dataStatus))
    .filter((item) => !item.isChildOf || _.includes(itemChildrenTypes, item.childType))
    .reject((item) => excludeEditingCondition && item.id === sqTrendCapsuleStore.editingId)
    .thru((items) =>
      workingSelection && _.some(items, 'selected')
        ? _.filter(items, (item) => item.selected || item.id === PREVIEW_ID)
        : items,
    )
    .thru(transformer)
    .value();
};
